<template>
  <div class="zadi-magazines-page">
    <page-header title="أعداد مجلة زادي" />
    <infinite-scroll-wrapper @fetch-data="fetchAllMagazines" :limit="limit" :total="total"
      :is-loading="isLoadingMagazines">
      <div class="row">
        <template>
          <div v-for="magazine in magazines" :key="magazine.id" class="col-lg-3 col-md-4 col-sm-6 col-12">
            <magazine-card :magazine="magazine" @share-magazine="onShareMagazine" />
          </div>
        </template>
        <template v-if="isLoadingMagazines">
          <div v-for="n in limit" :key="n" class="col-lg-3 col-md-4 col-sm-6 col-12">
            <magazine-card-skelton />
          </div>
        </template>
      </div>
    </infinite-scroll-wrapper>
    <share-popup :entity="magazine" />
  </div>
</template>

<script>
import PageHeader from "../../website-components/zadi/shared/PageHeader.vue";
import MagazineCard from "../../website-components/zadi/magazines/MagazineCard.vue";
import InfiniteScrollWrapper from "../../website-components/zadi/shared/InfiniteScrollWrapper.vue";
import MagazineCardSkelton from "../../website-components/zadi/magazines/MagazineCardSkelton.vue";
import SharePopup from "../../website-components/zadi/shared/SharePopup.vue";
import zadiRepository from "../../repositories/zadiRepository";

export default {
  metaInfo: {
    title: "مشروع زادي | أعداد المجلة ",
  },
  components: { MagazineCard, MagazineCardSkelton, InfiniteScrollWrapper, SharePopup, PageHeader },
  data() {
    return {
      magazines: [],
      isLoadingMagazines: false,
      page: 1,
      limit: 4,
      total: null,
      magazine: null
    }
  },
  methods: {
    async fetchAllMagazines({ page, limit }) {
      this.isLoadingMagazines = true;
      const res = await zadiRepository.getAllMagazines(page, limit);
      this.magazines = [...this.magazines, ...res?.data?.data];
      this.total = res?.data?.pageCount;
      this.isLoadingMagazines = false;
    },
    onShareMagazine(magazine) {
      this.magazine = magazine;
      this.$nextTick(() => {
        $(`#share-popup-magazine`).modal('show');
      });
    }
  },
  async mounted() {
    await this.fetchAllMagazines({
      page: this.page,
      limit: this.limit,
    })
  }
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap");

.zadi-magazines-page {
  padding: 120px 40px 80px 40px;
  font-family: "Tajawal", sans-serif;
}

.row {
  margin-left: -8px;
  margin-right: -8px;
}

.col-12 {
  padding: 8px;
}

@media (max-width: 560px) {
  .zadi-magazines-page {
    padding: 120px 20px 80px 20px;
  }
}
</style>
